import { FC, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { cloneDeep } from "lodash";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Box } from "@material-ui/core";
import { BaselineDialogProps, FormType } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import { enumToOptionsArrayWithTranslatedStrings } from "../../../../utils/obj-utils";
import {
  FacilityType,
  StageType,
  TransportMode,
} from "../../../../../../graphql/types";
import { PigBaseline, PigStage } from "../../../../models/Baseline/PigBaseline";
import {
  ProcessingInput,
  ProcessingStage,
  ProcessingStageData,
} from "../../../../models/Facility/Processing";
import SustellTypeAmountFields from "../../../../../modules/Helpers/SustellTypeAmountFIelds";
import {
  isBeefFacility,
  isCuttingFacility,
  isMarineFishFacility,
  isPigFacility,
} from "../../../../helpers/facilities";
import { FacilityTableItem } from "../../../../models/Facility/FacilityTypes";
import ReactHookDsmRadioGroup from "../../../../../modules/Helpers/ReactHookDsmRadioGroup";
import { AnimalType } from "../../../../../../graphql/generated/blonk/cattle";
import { AnimalType as PigAnimalType } from "../../../../../../graphql/generated/blonk/pigs";
import { ReactChangedType } from "../../../../helpers/helperTypes";
import lookupValues from "../../../../helpers/lookupValues";

interface SlaughterhouseAnimalsFormDialogProps extends BaselineDialogProps {
  // eslint-disable-next-line react/require-default-props
  formType: FormType;
  itemIndex: number;
  formVisible: boolean;
  formTitle: string;
  facility: FacilityTableItem | undefined;
  iconCode: string;
}

const SlaughterhouseAnimalsFormDialog: FC<
  SlaughterhouseAnimalsFormDialogProps
> = ({
  handleCancel,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  formTitle,
  facility,
  iconCode,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;
  const isCutting = isCuttingFacility(facility?.type);
  const isMarineFish = isMarineFishFacility(facility?.targetSpecies.at(0));
  const isBeef = isBeefFacility(facility?.targetSpecies.at(0));
  const isPig = isPigFacility(facility?.targetSpecies.at(0));

  const fieldItemPrefix = `stages.${itemIndex}.stageData.processingInput`;
  const currResetValue = useRef<ProcessingInput>();
  const formContext = useFormContext<PigBaseline>();
  type StageType = { name: string; id: string; facilityType?: string };
  const [, forceUpdate] = useState<object>({});
  const beefInputDefaultValue = lookupValues.processingInputDefaultValues.BEEF;

  const mountStage = (stage: StageType) => {
    const facilityType = stage?.facilityType;
    const facilityName = facilityType
      ? `(${intl.formatMessage({
          id: `SUSTELL.SLAUGHTERHOUSE.ENUMS.STAGE.TYPE.${facilityType}`,
        })})`
      : "";

    return {
      text: `${stage.name} ${facilityName}`,
      value: stage.id,
    };
  };

  const mountStages = (stages: Array<PigStage | ProcessingStage>) => {
    if (!stages || !stages.length) return [];
    return stages?.map(mountStage);
  };

  const animalTypeBeefOptions = () =>
    Object.entries(AnimalType).map(([label, value]) => ({
      label,
      value,
    }));

  const animalTypePigOptions = () =>
    Object.entries(PigAnimalType).map(([label, value]) => ({
      label,
      value,
    }));

  // If facility type is cutting, only SLAUGHTERING stages can be input stages
  // If facility type is slaughtering or slaughtering & cutting, only FATTENING, BREEDING and GROWING stages can be input stages.
  const getAvailableStages = () => {
    const allStages = formContext.getValues("stages");
    if (isCuttingFacility(facility?.type)) {
      return mountStages(
        allStages?.filter(
          (stage) =>
            "facilityType" in stage &&
            stage?.facilityType === FacilityType.Slaughtering
        )
      );
    }

    return mountStages(
      allStages.filter(
        (stage) =>
          stage.type === StageType.Fattening ||
          stage.type === StageType.Growing ||
          ((isBeef || isPig) && stage.type === StageType.Breeding)
      )
    );
  };

  const {
    fields: transport,
    append: appendTransport,
    remove: removeTransport,
  } = useFieldArray({
    name: `${fieldItemPrefix}.transport`,
    control: formContext.control,
    keyName: "keyId",
  });

  useEffect(() => {
    if (formVisible) {
      // show for the first element empty, if there is no transport elements
      if (!transport?.length)
        appendTransport({ transportMode: "", distance: "" });

      currResetValue.current = cloneDeep<ProcessingInput>(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() };
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as ProcessingStageData;
      if (stageData?.processingInput) {
        stageData.processingInput = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel("reset");
  };

  const getTransportTypeOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      TransportMode,
      intl,
      "SUSTELL.TRANSPORT_MODE"
    );

  const getGridClasss = (index: number): string =>
    index === 0
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (classes.dsmGridTwoColumnNoRowSpacing as string)
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (classes.additionalEntriesBlockHolder as string);

  const getGridStyle = (index: number) =>
    index !== 0
      ? {
          grid: "auto/0.7fr 0.7fr 14px",
          gap: "var(--dsm-spacing-px-6)",
          alignItems: "start",
          alignContent: " space-between",
          padding: "10px 4px 0px 4px",
        }
      : {
          grid: "auto/0.7fr 0.7fr 20px",
        };

  const revaliadatePigsToAnotherStage = () => {
    const formData = formContext.getValues();

    // if input stage is chagend, do a revalidation of pigsToStage field for all stages
    formData?.stages?.forEach((stage, index) => {
      if (stage.type !== StageType.Processing)
        formContext
          .trigger(`stages.${index}.stageData.output.pigsToStage`)
          .then()
          .catch((err: Error) =>
            console.log(`Error triggering form validation: ${err.message}`)
          );
    });
  };

  const updateStageType = (stageId: string) => {
    const allStages = formContext.getValues("stages");
    const selectedStage = allStages?.find((stage) => stage.id === stageId);

    formContext.setValue(`${fieldItemPrefix}.stageType`, selectedStage?.type);

    if (selectedStage && selectedStage.type === StageType.Growing) {
      formContext.setValue(`${fieldItemPrefix}.animalType`, AnimalType.Cow);
    }
  };

  const handleStageChange = (e: ReactChangedType) => {
    if (isBeef || isPig) {
      updateStageType(e.target.value as string);
    } else {
      revaliadatePigsToAnotherStage();
    }

    forceUpdate({});
  };
  const getDefaultValues = () => {
    const growingStageIndex = 0;
    const slaughteringStageIndex = 1;
    const originStageIndex = isCutting
      ? slaughteringStageIndex
      : growingStageIndex;

    const averageWeight: string =
      formContext.getValues(`${fieldItemPrefix}.averageWeight`) ||
      formContext.getValues(
        `stages.${growingStageIndex}.stageData.output.averageWeight`
      );
    const originStageId: string = isMarineFish
      ? formContext.getValues(`stages.${originStageIndex}.id`)
      : formContext.getValues(`${fieldItemPrefix}.originStageId`);

    return {
      averageWeight, // Average weight only applies do MarineFish
      originStageId,
    };
  };

  const updateSelection = async () => {
    await formContext.trigger(`${fieldItemPrefix}.animalType`);
    forceUpdate({});
  };

  const shouldShowAnimalType = (): boolean => {
    const selectedStageType = formContext.getValues(
      `${fieldItemPrefix}.stageType`
    );

    if (selectedStageType && selectedStageType === StageType.Breeding)
      return true;

    return false;
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode={iconCode}
      variant="wide"
      formTitle={formTitle}
      introText={
        !isCutting
          ? intl.formatMessage({
              id: "SUSTELL.STAGES.PROCESSING.ANIMALS.DESCRIPTION",
            })
          : intl.formatMessage({
              id: "SUSTELL.STAGES.PROCESSING.ANIMALS_CARCASES.DESCRIPTION",
            })
      }
    >
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={formContext.getValues(`${fieldItemPrefix}.stageType`)}
      />
      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        className={classes.dsmGridTwoColumnNoRowSpacing}
        style={{ grid: "auto/0.7fr 0.7fr 20px" }}
      >
        <ReactHookDsmSelect
          style={{ marginBottom: "12px" }}
          name={`${fieldItemPrefix}.originStageId`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE",
          })}
          tooltip={
            !isCutting
              ? intl.formatMessage({
                  id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.TT",
                })
              : intl.formatMessage({
                  id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE_CARCASES.TT",
                })
          }
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER",
          })}
          options={getAvailableStages().filter(
            (item: { value: unknown }) =>
              item.value !== formContext.getValues(`stages.${itemIndex}.id`)
          )}
          required
          defaultValue={getDefaultValues().originStageId}
          changeHandler={handleStageChange}
        />
        {(isBeef || isPig) && shouldShowAnimalType() && (
          <ReactHookDsmRadioGroup
            name={`${fieldItemPrefix}.animalType`}
            label={`${intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.INPUT.ANIMAL_TYPE",
            })}`}
            options={isBeef ? animalTypeBeefOptions() : animalTypePigOptions()}
            disabled={isBeef ? formType === FormType.View : true}
            defaultValue={isBeef ? formContext.getValues(
              `${fieldItemPrefix}.animalType`
            ): PigAnimalType.Sow}
            required
            changeHandler={updateSelection}
          />
        )}
        {!isCutting && !isMarineFish && (
          <>
            {(!isBeef || !isPig) && !shouldShowAnimalType() && <Box />}
            <Box />
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.quantity`}
              label={intl.formatMessage({
                id: "COMPOUND_FEED.BASE.FEED_PLACEHOLDER",
              })}
              disabled={formType === FormType.View}
              tooltip={
                !isCutting
                  ? intl.formatMessage({
                      id: "SUSTELL.STAGES.PROCESSING.ANIMALS.QUANTITY.TT",
                    })
                  : intl.formatMessage({
                      id: "SUSTELL.STAGES.PROCESSING.ANIMALS_CARCASES.QUANTITY.TT",
                    })
              }
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER",
              })}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.quantity`
              )}
              type="number"
              required
            />
          </>
        )}

        {
          // for pig cutting and for marine fish cutting and slaughtering quantity is set to 1
          (isCutting || isMarineFish) && (
            <input
              ref={formContext.register()}
              type="hidden"
              name={`${fieldItemPrefix}.quantity`}
              value={1}
            />
          )
        }

        {!isCutting && isMarineFish && (
          <>
            <Box />
            <Box />
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeight`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGES.PROCESSING.ANIMALS.AVERAGE_WEIGHT",
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGES.PROCESSING.FISH.AVERAGE_WEIGHT.TT",
              })}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGES.PROCESSING.ANIMALS.AVERAGE_WEIGHT_UNIT",
              })}
              required
              type="number"
              defaultValue={getDefaultValues().averageWeight}
            />
          </>
        )}
      </DsmGrid>
      {transport?.map((item, index) => (
        <DsmGrid
          className={getGridClasss(index)}
          style={getGridStyle(index)}
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`key_transport_${item.keyId}`}
        >
          <SustellTypeAmountFields
            labelInput={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.TRANSPORT_DISTANCE",
            })}
            labelSelect={intl.formatMessage({ id: "SUSTELL.TRANSPORT_MODE" })}
            placeholderSelect={intl.formatMessage({
              id: "SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER",
            })}
            options={getTransportTypeOptions()}
            index={index}
            formType={formType}
            fieldItemPrefix={`${fieldItemPrefix}.transport`}
            typeFieldName="transportMode"
            amountFieldName="distance"
            deafultValueType={formContext.getValues(
              `${fieldItemPrefix}.transport.${index}.transportMode`
            )}
            defaultValueAmount={formContext.getValues(
              `${fieldItemPrefix}.transport.${index}.distance`
            )}
            placeholderUnit="km"
            keyName="sl-animals-transports"
            tooltipType={
              !isCutting
                ? intl.formatMessage({
                    id: "SUSTELL.STAGES.PROCESSING.TRANSPORT_MODE.TT",
                  })
                : intl.formatMessage({
                    id: "SUSTELL.STAGES.PROCESSING.TRANSPORT_MODE_CARCASES.TT",
                  })
            }
          />
          {formType !== FormType.View && index !== 0 && (
            <DsmButton
              variant="text"
              style={{ position: "relative", width: "100%" }}
              onClick={() => removeTransport(index)}
            >
              <DsmIcon
                name="general/x-close"
                style={{
                  position: "absolute",
                  color: "var(--dsm-color-neutral-darker",
                  right: "0",
                }}
              />
            </DsmButton>
          )}
        </DsmGrid>
      ))}
      <DsmButton
        variant="text"
        onClick={() => appendTransport({ transportMode: "", distance: "" })}
        disabled={formType === FormType.View}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({
          id: "SUSTELL.FACILITIES.RESOURCES.ADD_ANOTHER_TRANSPORT_MODE",
        })}
      </DsmButton>

      {isBeef && (
        <DsmGrid
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          className={classes.dsmGridTwoColumnNoRowSpacing}
          style={{ grid: "auto/0.7fr 0.7fr 20px", marginTop: "16px" }}
        >
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.shrinkingRate`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.INPUT.WEIGHT_LOSS",
            })}
            disabled={formType === FormType.View}
            adornment="%"
            type="number"
            tooltip={intl.formatMessage({
              id: `SUSTELL.STAGE.BEEF.INPUT.WEIGHT_LOSS.TOOLTIP`,
            })}
            placeholder={intl.formatMessage({
              id: `SUSTELL.STAGE.BEEF.INPUT.WEIGHT_LOSS.PLACEHOLDER`,
            })}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.shrinkingRate`) ||
              beefInputDefaultValue.shrinkingRate
            }
          />
        </DsmGrid>
      )}

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(
            `stages.${itemIndex}.stageData.processingInput`
          );
          handleCancel("confirm");
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default SlaughterhouseAnimalsFormDialog;
