/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { API } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  getFarmBaselineByReference,
  getFootprintInner,
  getInterventionByReference,
} from "../../../../graphql/queries";
import { FootprintModel } from "../../models/Footprint";
import { getFootprintType } from "../../utils/footprint-url-utils";
import {
  AnimalType,
  FarmBaseline,
  Footprint,
  FootPrintType,
  Intervention,
  Maybe,
  Scalars,
  StageType,
} from "../../../../graphql/types";
import {
  isPigFootprint,
  isPoultryFootprint,
  isShrimp,
  isBeefFootprint,
  isBeef,
  isDairy,
} from "../../helpers/animals";
import {
  FootprintCategoryName,
  StageEmissions,
} from "../../models/Footprint/FootprintTypes";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { FacilityTableItem } from "../../models/Facility/FacilityTypes";
import lookupValues from "../../helpers/lookupValues";

/**
 * Build the combined query to fetch a baseline and comparison footprint for feed
 *
 * @param includeComparisonType if the query should also fetch a comparison
 * @returns a GraphQL query as a string
 */
const buildFootprintQuery = (includeComparison: boolean) => /* GraphQL */ `
    query getFootprints($customerId: ID!, $reference: [ID!]!, $type: FootPrintType! ${
      includeComparison
        ? ", $comparisonCustomerId: ID!, $comparisonReference: [ID!]!, $comparisonType: FootPrintType!"
        : ""
    }) {
      baseline: ${getFootprintInner("$customerId", "$reference", "$type")}
      ${
        includeComparison
          ? `,comparison: ${getFootprintInner(
              "$comparisonCustomerId",
              "$comparisonReference",
              "$comparisonType"
            )}`
          : ""
      }
    }
  `;

interface FootprintProps {
  baselineName?: string;
  customerID: string;
  baselineFarmID: string;
  baselineProcessID: string;
  baselineReference?: string;
  baselineType?: string;
  comparisonName?: string;
  comparisonFarmID?: string;
  comparisonReference?: string;
  comparisonType?: string;
  comparisonCustomerID?: string;
  facilities: FacilityTableItem[] | null;
  baselineAnimalType?: AnimalType;
}

export interface FootprintStageData {
  animalType: string;
  calculatedImpact: Maybe<Scalars["AWSJSON"]>;
  outputWeight: number;
  stageId: string;
  stageName: string;
  stageType: string;
  stageEmission: StageEmissions;
  isDefaultStage: boolean;
  facilityCategory?: string;
  originStageId?: string;
  facilityId?: string;
  originStageType?: string;
  // For processing stages here will be collection of output footrpints per category
  processingStageData?: FootprintStageData[] | undefined;
  farmId?: string;
  farmName?: string;
}

const useFootprint = ({
  customerID,
  baselineName,
  baselineFarmID,
  baselineReference,
  baselineType,
  comparisonCustomerID,
  comparisonName,
  comparisonReference,
  comparisonType,
  facilities,
  baselineAnimalType,
}: FootprintProps) => {
  const [footprint, setFootprint] = useState<FootprintModel>();
  const intl = useIntl();

  const [, updateRenderState] = useState<object>();
  const forceUpdate = () => updateRenderState({});

  /**
   * Set stage for the output with the highest production weight (one from largest growing stage, and one from largest breeding stage)
   */
  const [footprintStages, setFootprintStages] =
    useState<FootprintStageData[]>();

  /**
   * Set stage for the output with the highest production weight (one from largest growing stage, and one from largest breeding stage)
   */
  const [comparisonFootprintStages, setComparisonFootprintStages] =
    useState<FootprintStageData[]>();

  const [footprintSelectedStage, setFootprintSelectedStage] =
    useState<FootprintStageData>();

  /**
   * Only show categories for the specified filter.
   * Can be used multiple times to allow filtering on categories.
   * @param category - the category to add to the filters
   */
  const setCategoryFilters = (categories: FootprintCategoryName[]) => {
    if (!footprint) return;
    footprint.setFilters({ categories });
  };

  /**
   * Only show analysis groups for the specified filter.
   * Can be used multiple times to allow filtering on multiple groups.
   * @param analysisGroup - the group to add to the filters
   */
  const setAnalysisGroupFilters = (analysisGroups: string[]) => {
    if (!footprint) return;

    footprint.setFilters({ analysisGroups });
  };

  const getKeyByValue = (value: string) =>
    Object.entries(AnimalType).find(([, name]) => value === name);

  /**
   *
   * @param stageData - stage data to show footprint
   */
  const setStageFilter = (stageData: FootprintStageData) => {
    if (stageData.calculatedImpact) {
      const keyValueAray = getKeyByValue(stageData.animalType);
      stageData.animalType =
        keyValueAray && keyValueAray.length > 0
          ? keyValueAray[0]
          : stageData.animalType;
      setFootprintSelectedStage(stageData);
      footprint?.updateCategories(JSON.stringify(stageData.calculatedImpact));
      const facility = (facilities || []).find(
        (f) => f.id === stageData.facilityId
      );
      if (stageData.facilityId && facility) {
        footprint?.setFacility(facility);
      }
      forceUpdate();
    }
    if (stageData?.stageEmission) {
      footprint?.updateEmissions(stageData?.stageEmission);
      forceUpdate();
    }
    if (footprint) {
      footprint.setStageType(stageData.stageType as StageType);
    }
    // Update stages when compare
    if (footprint?.comparison) {
      let comparisonFootprintStage = comparisonFootprintStages?.find(
        (cfs) =>
          cfs.stageType === stageData.stageType &&
          cfs.animalType === stageData.animalType
      );
      if (
        comparisonFootprintStage &&
        comparisonFootprintStage.stageType === StageType.Processing
      ) {
        comparisonFootprintStage =
          comparisonFootprintStage.processingStageData?.find(
            (pfs) => pfs.facilityCategory === stageData.facilityCategory
          );
      }

      if (comparisonFootprintStage?.stageEmission) {
        footprint?.updateCompareEmissions(
          comparisonFootprintStage?.stageEmission
        );
        forceUpdate();
      } else {
        footprint?.updateCompareEmissions(undefined);
        forceUpdate();
      }
      if (comparisonFootprintStage?.calculatedImpact) {
        footprint?.updateCompareCategories(
          JSON.stringify(comparisonFootprintStage.calculatedImpact)
        );
        forceUpdate();
      } else {
        footprint?.updateCompareCategories(JSON.stringify(undefined));
        forceUpdate();
      }
    }
  };

  const fetchFootprintData = useCallback(async () => {
    if (!baselineType) return { baseline: undefined };

    const queryWithVars = {
      query: buildFootprintQuery(!!comparisonType),
      variables: {
        customerId: customerID,
        reference: [baselineReference],
        type: getFootprintType(baselineType),
        comparisonCustomerId: comparisonCustomerID,
        comparisonReference: [comparisonReference],
        comparisonType: getFootprintType(comparisonType),
      },
    };
    const result = await (API.graphql(queryWithVars) as Promise<{
      data: {
        baseline: Footprint[];
        comparison: Footprint[] | undefined;
      };
    }>);

    let target;
    if (baselineType !== "f") {
      const dataSetQueryWithVars = {
        query:
          baselineType === "b"
            ? getFarmBaselineByReference
            : getInterventionByReference,
        variables: {
          farmId: baselineFarmID,
          reference: baselineReference,
        },
      };

      const dataSetResult = await (API.graphql(
        dataSetQueryWithVars
      ) as Promise<{
        data: {
          getFarmBaselineByReference: FarmBaseline;
          getInterventionByReference: Intervention;
        };
      }>);
      target = dataSetResult?.data?.getFarmBaselineByReference?.target
        ? dataSetResult?.data?.getFarmBaselineByReference?.target
        : dataSetResult?.data?.getInterventionByReference?.target;
    }
    const comparison = comparisonReference
      ? result?.data?.comparison?.[0] || {
          reference: comparisonReference,
        }
      : undefined;
    return {
      target,
      baseline: result.data?.baseline[0] || { reference: baselineReference },
      comparison,
    };
  }, [
    baselineReference,
    baselineFarmID,
    baselineType,
    comparisonType,
    comparisonReference,
    customerID,
    comparisonCustomerID,
  ]);

  const getBeefDisplayedAnimalType = (
    animalType: string,
    stageType: string
  ) => {
    let animalTypeToBeDisplayed = `${intl.formatMessage({
      id: `FARM.ANIMALTYPE.LABEL.${animalType}`,
    })}`;
    if (stageType === StageType.Growing) {
      animalTypeToBeDisplayed = `${intl.formatMessage({
        id: `FARM.ANIMALTYPE.LABEL.CATTLE`,
      })}`;
    }
    return animalTypeToBeDisplayed;
  };

  const getDairyDisplayedAnimalType = (animalType: string) => {
    let animalTypeToBeDisplayed = `${intl.formatMessage({
      id: `FARM.ANIMALTYPE.LABEL.${animalType}`,
    })}`;
    return animalTypeToBeDisplayed;
  };

  const mountStageNameToBeDisplayed = (
    animalType: string,
    stageType: string,
    stageName: string
  ): string => {
    let stageNameToDisplay = "";
    console.log(stageType, "stageType");
    if (stageType && stageType !== "undefined") {
      stageNameToDisplay = `${intl.formatMessage({
        id: `SUSTELL.ENUMS.STAGE.TYPE.${stageType}`,
      })} - ${intl.formatMessage({
        id: "SUSTELL.ENUMS.STAGE.TYPE.PROCESSING",
      })} (${stageName})`;
    } else {
      stageNameToDisplay = `${intl.formatMessage({
        id: "SUSTELL.ENUMS.STAGE.TYPE.PROCESSING",
      })} (${stageName})`;
    }

    if (
      isBeefFootprint(animalType) &&
      (stageType === StageType.Breeding || stageType === StageType.Growing)
    ) {
      return `${stageNameToDisplay} - ${getBeefDisplayedAnimalType(
        animalType,
        stageType
      )}`;
    }

    return stageNameToDisplay;
  };

  const getProcessingDataForStage = (
    stagesImpactData: string,
    animalType?: string,
    stageType?: string
  ): FootprintStageData[] => {
    const stagesImpact = (JSON.parse(stagesImpactData) ||
      []) as FootprintStageData[];

    const processingStages = stagesImpact.filter(
      (stage) => stage?.stageType === StageType.Processing
    );

    stagesImpact.forEach((stageImpact) => {
      const existingItem = processingStages.find(
        (processingStage) =>
          stageImpact.stageType === StageType.Processing &&
          stageImpact.stageName === processingStage.stageName &&
          stageImpact.originStageId === processingStage.originStageId
      );
      // If there is reference to slaughtering stage add it
      if (
        !existingItem &&
        processingStages.some(
          (processingStage) =>
            processingStage.stageId === stageImpact?.originStageId
        )
      ) {
        processingStages.push(stageImpact);
      }
    });
    if (!processingStages || processingStages.length === 0) {
      return [];
    }

    // Per processing stage, embedded array for footprint output categories
    const retVal: FootprintStageData[] = [];
    const processingStagesNames = new Set(
      processingStages.map((stage) => stage.stageName)
    );

    Array.from(processingStagesNames).forEach((processingStageName: string) => {
      const processingStageFootprints =
        processingStages.filter(
          (processingStage) => processingStage.stageName === processingStageName
        ) || [];

      const freshMeatFootprintIndex = processingStageFootprints.findIndex(
        (processingStage) =>
          processingStage.facilityCategory ===
          footprintSelectedStage?.facilityCategory
      );
      const freshMeatFootprint = processingStageFootprints.at(
        freshMeatFootprintIndex
      );
      // Sort by categories
      processingStageFootprints.sort(
        (a, b) =>
          Object.keys(lookupValues.processingOutputTypeMappings).indexOf(
            a?.facilityCategory || ""
          ) -
          Object.keys(lookupValues.processingOutputTypeMappings).indexOf(
            b?.facilityCategory || ""
          )
      );

      if (!freshMeatFootprint) return;
      const processingAnimalType = freshMeatFootprint.animalType || animalType;
      const processingStageType =
        String(freshMeatFootprint?.originStageType) || stageType;
      const stageNameToDisplay = mountStageNameToBeDisplayed(
        processingAnimalType as string,
        processingStageType as string,
        freshMeatFootprint.stageName
      );
      const stageData: FootprintStageData = {
        animalType: processingAnimalType as string,
        outputWeight: freshMeatFootprint.outputWeight,
        stageId: freshMeatFootprint.stageId,
        stageEmission: freshMeatFootprint.stageEmission,
        facilityId: freshMeatFootprint.facilityId,
        originStageId: freshMeatFootprint.originStageId,
        stageName: stageNameToDisplay,
        stageType: freshMeatFootprint.stageType,
        originStageType: stagesImpact?.find(
          (stage) => stage.stageId === freshMeatFootprint.originStageId
        )?.stageType,
        calculatedImpact: freshMeatFootprint.calculatedImpact,
        processingStageData: processingStageFootprints,
        facilityCategory: freshMeatFootprint.facilityCategory,
        isDefaultStage: freshMeatFootprint.isDefaultStage,
        farmId: freshMeatFootprint.farmId,
        farmName: freshMeatFootprint.farmName,
      };
      retVal.push(stageData);
    });

    return retVal;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPigStageFootprints = (stagesImpact: any): FootprintStageData[] => {
    const stagesData: FootprintStageData[] = [];
    for (const stage of stagesImpact) {
      if (isPigFootprint(stage.animalType as AnimalType)) {
        stagesData.push(stage);
      }
    }
    const animalType = stagesData[0]?.animalType
      ? stagesData[0]?.animalType
      : (stagesImpact[0]?.animalType as string);

    const processingImpactData = getProcessingDataForStage(
      JSON.stringify(stagesImpact),
      animalType,
      StageType.Fattening
    );
    if (processingImpactData.length) {
      processingImpactData.forEach((item) => stagesData.push(item));
    }
    return stagesData;
  };

  // TODO: In next tasks when the default one is defined, change the logic here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPoultryStageFootprints = (
    stagesImpact: any
  ): FootprintStageData[] => {
    const stagesData = [] as FootprintStageData[];
    for (const stage of stagesImpact) {
      if (
        isPoultryFootprint(stage.animalType as AnimalType) &&
        stage.stageType === StageType.Growing
      ) {
        stagesData.push(stage);
      }
    }

    const animalType = stagesData[0]?.animalType
      ? stagesData[0]?.animalType
      : (stagesImpact[0]?.animalType as string);

    const processingImpactData = getProcessingDataForStage(
      JSON.stringify(stagesImpact),
      animalType,
      StageType.Growing
    );
    if (processingImpactData.length) {
      processingImpactData.forEach((item) => stagesData.push(item));
    }

    const remainingStages = stagesImpact?.filter(
      (stage: { stageType: StageType }) =>
        stage.stageType !== StageType.Growing &&
        stage.stageType !== StageType.Processing
    ) as FootprintStageData[];

    return [...stagesData, ...remainingStages] as FootprintStageData[];
  };

  const getShrimpStageFootprints = (
    stagesImpact: any
  ): FootprintStageData[] => {
    const stagesData: FootprintStageData[] = [];
    stagesData.push(...(stagesImpact as FootprintStageData[]));
    return stagesData;
  };

  const getBeefStageFootprints = (stagesImpact: any): FootprintStageData[] => {
    if (!Array.isArray(stagesImpact)) return [];

    const { breedingAndGrowingStages, processingStages } = stagesImpact.reduce<{
      breedingAndGrowingStages: FootprintStageData[];
      processingStages: FootprintStageData[];
    }>(
      (acc, stage: FootprintStageData) => {
        if (stage.stageType === StageType.Processing) {
          acc.processingStages.push(stage);
          return acc;
        }

        if (
          stage.stageType === StageType.Breeding ||
          stage.stageType === StageType.Growing
        ) {
          const newStage: FootprintStageData = {
            ...stage,
            stageName: `${stage.stageName} - ${getBeefDisplayedAnimalType(
              stage.animalType.toUpperCase(),
              stage.stageType
            )}`,
          };
          acc.breedingAndGrowingStages.push(newStage);
          return acc;
        }

        acc.breedingAndGrowingStages.push(stage);
        return acc;
      },
      { breedingAndGrowingStages: [], processingStages: [] }
    );

    const processingStageData = getProcessingDataForStage(
      JSON.stringify(processingStages)
    );
    return [...processingStageData, ...breedingAndGrowingStages];
  };

  const getDairyStageFootprints = (stagesImpact: any): FootprintStageData[] => {
    if (!Array.isArray(stagesImpact)) return [];

    const { dairyCowAndGrowingStages, processingStages } = stagesImpact.reduce<{
      dairyCowAndGrowingStages: FootprintStageData[];
      processingStages: FootprintStageData[];
    }>(
      (acc, stage: FootprintStageData) => {
        if (stage.stageType === StageType.Processing) {
          acc.processingStages.push(stage);
          return acc;
        }
        acc.dairyCowAndGrowingStages.push(stage);
        return acc;
      },
      { dairyCowAndGrowingStages: [], processingStages: [] }
    );

    const processingStageData = getProcessingDataForStage(
      JSON.stringify(processingStages)
    );
    return [...processingStageData, ...dairyCowAndGrowingStages];
  };

  const getStagesData = (
    stagesImpactData: string
  ): [FootprintStageData[], string] => {
    const stagesImpact = JSON.parse(stagesImpactData);
    if (stagesImpact?.length === 0) return [[], ""];
    const animalType: string = stagesImpact[0]?.animalType
      ? stagesImpact[0]?.animalType
      : "";
    if (isPigFootprint(animalType))
      return [getPigStageFootprints(stagesImpact), animalType];
    if (isPoultryFootprint(animalType))
      return [getPoultryStageFootprints(stagesImpact), animalType];
    if (isShrimp(baselineAnimalType))
      return [getShrimpStageFootprints(stagesImpact), animalType];
    if (isBeef(baselineAnimalType))
      return [getBeefStageFootprints(stagesImpact), animalType];
    if (isDairy(baselineAnimalType))
      return [getDairyStageFootprints(stagesImpact), animalType];
    return [[], animalType];
  };

  const getStageEmissions = (
    stagesData: FootprintStageData[],
    emissions: string,
    footprint: FootprintModel | undefined
  ) => {
    let defaultEmission = null;
    const stageEmissions = JSON.parse(emissions);
    stagesData.forEach((stage) => {
      const stageEmission = stageEmissions.find(
        (emission: { id: string }) => emission.id === stage.stageId
      );
      if (stageEmission) {
        stage.stageEmission = stageEmission;
        if (
          footprint?.stageType === StageType.Breeding &&
          stage.stageType === StageType.Breeding &&
          stage.animalType === footprintSelectedStage?.animalType &&
          stage.stageId === footprintSelectedStage?.stageId
        ) {
          defaultEmission = stageEmission;
        } else if (
          footprint?.stageType === StageType.Processing &&
          stage.stageType === StageType.Processing &&
          stage.animalType === footprintSelectedStage?.animalType &&
          stage.stageId === footprintSelectedStage?.stageId
        ) {
          defaultEmission = stageEmission;
        } else if (
          footprint?.stageType === StageType.Fattening &&
          stage.stageType === StageType.Fattening &&
          stage.animalType === footprintSelectedStage?.animalType &&
          stage.stageId === footprintSelectedStage?.stageId
        ) {
          defaultEmission = stageEmission;
        } else if (stage.stageType === StageType.Fattening) {
          defaultEmission = stageEmission;
        }
      }
    });
    return defaultEmission;
  };

  const getStageEmissionsPoultry = (
    stagesData: FootprintStageData[],
    emissions: string,
    stageId: string
  ) => {
    const stageEmissions = JSON.parse(emissions);
    stagesData.forEach((stage) => {
      const stageEmission = stageEmissions.find(
        (emission: { id: string }) => emission.id === stage.stageId
      );
      if (stageEmission) {
        stage.stageEmission = stageEmission;
      }
    });
    return stagesData.find((stage) => stage.stageId === stageId)?.stageEmission;
  };

  const getDefaultPigImpact = (
    stagesData: FootprintStageData[],
    isCompare: boolean
  ) => {
    if (isCompare) {
      const isComparedStage = stagesData.find(
        (sd) => sd.stageType === footprintSelectedStage?.stageType
      );
      return [
        JSON.stringify(isComparedStage?.calculatedImpact),
        isComparedStage?.stageId ?? "",
      ];
    }

    let defaultStage = stagesData.find((sd) => sd.isDefaultStage) || null;
    if (defaultStage) {
      return makeDefaultImpact(defaultStage);
    }

    const breedingStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Breeding && sd.animalType === "Piglet"
    );
    defaultStage = breedingStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );

    if (defaultStage) {
      makeDefaultImpact(defaultStage);
    }

    const processingStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Processing
    );
    defaultStage = processingStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    if (defaultStage) {
      makeDefaultImpact(defaultStage);
    }

    const fatteningStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Fattening && sd.animalType === "Pig"
    );
    defaultStage = fatteningStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    return makeDefaultImpact(defaultStage);
  };

  const makeDefaultImpact = (
    defaultStage: FootprintStageData | null
  ): [string, string] => {
    if (!defaultStage) return ["", ""];

    return [
      JSON.stringify(defaultStage?.calculatedImpact),
      defaultStage.stageId,
    ];
  };

  const getDefaultBeefImpact = (
    stagesData: FootprintStageData[]
  ): [string, string] => {
    const defaultStage =
      stagesData.find((stageData) => stageData.isDefaultStage) || null;

    if (defaultStage) {
      return makeDefaultImpact(defaultStage);
    }

    return makeDefaultImpact(
      stagesData.reduce(
        (a: FootprintStageData, b: FootprintStageData) =>
          a?.outputWeight > b?.outputWeight ? a : b,
        null
      )
    );
  };

  const getDefaultPoultryImpact = (
    stagesData: FootprintStageData[],
    isCompare: boolean
  ): [string, string] => {
    if (isCompare) {
      const isComparedStage = stagesData.find(
        (sd) => sd.stageType === footprintSelectedStage?.stageType
      );
      return [
        JSON.stringify(isComparedStage?.calculatedImpact),
        isComparedStage?.stageId ?? "",
      ];
    }

    let defaultStage = stagesData.find((sd) => sd.isDefaultStage) || null;
    if (defaultStage) {
      return makeDefaultImpact(defaultStage);
    }
    const layingStages = stagesData.filter(
      (sd) =>
        sd.stageType === StageType.Laying && sd.animalType === "Consumption egg"
    );

    defaultStage = layingStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );

    if (defaultStage) {
      makeDefaultImpact(defaultStage);
    }

    const growingStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Growing
    );
    defaultStage = growingStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    if (defaultStage) {
      makeDefaultImpact(defaultStage);
    }
    const breedingStages = stagesData.filter(
      (sd) =>
        sd.stageType === StageType.Breeding && sd.animalType === "Hatching egg"
    );
    defaultStage = breedingStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    if (defaultStage) {
      makeDefaultImpact(defaultStage);
    }
    const hatchingStages = stagesData.filter(
      (sd) =>
        sd.stageType === StageType.Hatching &&
        (sd.animalType === "Day old chick" ||
          sd.animalType === "Day old turkey")
    );
    defaultStage = hatchingStages.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    return makeDefaultImpact(defaultStage);
  };

  const getDefaultShrimpImpact = (
    stagesData: FootprintStageData[],
    isCompare: boolean
  ): [string, string] => {
    if (isCompare) {
      const isComparedStage = stagesData.find(
        (sd) => sd.stageType === footprintSelectedStage?.stageType
      );
      return [
        JSON.stringify(isComparedStage?.calculatedImpact),
        isComparedStage?.stageId ?? "",
      ];
    }

    const isDefaultStage = stagesData.find((sd) => sd.isDefaultStage === true);
    if (isDefaultStage) {
      return [
        JSON.stringify(isDefaultStage.calculatedImpact),
        isDefaultStage.stageId,
      ];
    }
    const growingStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Growing
    );
    let defaultStage = growingStages?.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    if (defaultStage) {
      return makeDefaultImpact(defaultStage);
    }

    const nurseryStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Nursery
    );
    defaultStage = nurseryStages?.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    if (defaultStage) {
      return makeDefaultImpact(defaultStage);
    }
    const hatcheryStages = stagesData.filter(
      (sd) => sd.stageType === StageType.Hatching
    );
    defaultStage = hatcheryStages?.reduce(
      (a: FootprintStageData, b: FootprintStageData) =>
        a?.outputWeight > b?.outputWeight ? a : b,
      null
    );
    return makeDefaultImpact(defaultStage);
  };

  useEffect(() => {
    fetchFootprintData()
      .then((dataset) => {
        if (!dataset.baseline) {
          setFootprint(undefined);
          return;
        }

        if (footprint?.stageType) {
          footprint.setStageType(footprint.stageType);
        }

        let defaultEmission = null;
        if (dataset.baseline?.stagesImpact) {
          let stageId = "";
          const [stagesData, animalType] = getStagesData(
            dataset.baseline?.stagesImpact
          );
          if (isPigFootprint(animalType)) {
            let impact = "";
            [impact, stageId] = getDefaultPigImpact(stagesData, false);
            dataset.baseline.calculatedImpact = impact;
          }

          if (isPoultryFootprint(animalType)) {
            let impact = "";
            [impact, stageId] = getDefaultPoultryImpact(stagesData, false);
            dataset.baseline.calculatedImpact = impact;
          }

          if (isShrimp(baselineAnimalType)) {
            let impact = "";
            [impact, stageId] = getDefaultShrimpImpact(stagesData, false);
            dataset.baseline.calculatedImpact = impact;
          }

          if (isBeefFootprint(animalType)) {
            let impact = "";
            [impact, stageId] = getDefaultBeefImpact(stagesData);
            dataset.baseline.calculatedImpact = impact;
          }

          if (dataset.baseline?.emissions) {
            defaultEmission = isPoultryFootprint(animalType)
              ? getStageEmissionsPoultry(
                  stagesData,
                  dataset.baseline?.emissions,
                  stageId
                )
              : getStageEmissions(
                  stagesData,
                  dataset.baseline?.emissions,
                  footprint
                );
          }

          if (footprintSelectedStage && !defaultEmission) {
            defaultEmission = stagesData.find(
              (sd) =>
                (sd.stageType === StageType.Fattening ||
                  sd.stageType === StageType.Breeding ||
                  (sd.stageType === StageType.Processing &&
                    sd.stageId === footprintSelectedStage?.stageId)) &&
                footprintSelectedStage?.animalType === sd.animalType
            )?.stageEmission;
          }
          setFootprintStages(stagesData);
        }
        const baseline = new FootprintModel({
          name: baselineName ?? "Baseline",
          targets: dataset.target,
          ...dataset.baseline,
          type: getFootprintType(baselineType) as FootPrintType,
          stageEmission: defaultEmission,
          stageType: footprint?.stageType,
          isStagesImpact: Boolean(dataset.baseline?.stagesImpact),
        });
        if (dataset.comparison) {
          let defaultCompareStageEmission = null;
          const comparisonFootprint = dataset.comparison as Footprint;
          if (comparisonFootprint?.stagesImpact) {
            let stageId = "";
            const [stagesData, animalType] = getStagesData(
              comparisonFootprint.stagesImpact
            );
            if (isPigFootprint(animalType)) {
              let impact = "";
              [impact, stageId] = getDefaultPigImpact(stagesData, true);
              comparisonFootprint.calculatedImpact = impact;
            } else if (isPoultryFootprint(animalType)) {
              let impact = "";
              [impact, stageId] = getDefaultPoultryImpact(stagesData, true);
              comparisonFootprint.calculatedImpact = impact;
            } else if (isShrimp(baselineAnimalType)) {
              let impact = "";
              [impact, stageId] = getDefaultShrimpImpact(stagesData, true);
              comparisonFootprint.calculatedImpact = impact;
            } else if (isBeefFootprint(animalType)) {
              let impact = "";
              [impact, stageId] = getDefaultBeefImpact(stagesData);
              comparisonFootprint.calculatedImpact = impact;
            }
            if (comparisonFootprint?.emissions) {
              if (!isPoultryFootprint(animalType)) {
                defaultCompareStageEmission = getStageEmissions(
                  stagesData,
                  comparisonFootprint?.emissions,
                  footprint
                );
              } else {
                defaultCompareStageEmission = getStageEmissionsPoultry(
                  stagesData,
                  comparisonFootprint?.emissions,
                  stageId
                );
              }

              if (footprintSelectedStage) {
                if (isPigFootprint(animalType)) {
                  defaultCompareStageEmission = stagesData.find(
                    (sd) =>
                      (sd.stageType === StageType.Fattening ||
                        sd.stageType === StageType.Breeding ||
                        (sd.stageType === StageType.Processing &&
                          sd.stageId === footprintSelectedStage?.stageId)) &&
                      footprintSelectedStage?.animalType === sd.animalType &&
                      sd.stageType === footprintSelectedStage.stageType
                  )?.stageEmission;
                } else if (
                  isPoultryFootprint(animalType) ||
                  isShrimp(baselineAnimalType) ||
                  isBeefFootprint(animalType)
                ) {
                  defaultCompareStageEmission = stagesData.find(
                    (sd) =>
                      sd.stageId === stageId &&
                      sd.stageType === footprintSelectedStage.stageType
                  )?.stageEmission;
                }
              }
            }
            if (!defaultCompareStageEmission) {
              comparisonFootprint.emissions = null;
            }
            setComparisonFootprintStages(stagesData);
          } else {
            setComparisonFootprintStages([]);
          }
          baseline.comparison = new FootprintModel({
            name: comparisonName ?? "Comparison",
            ...comparisonFootprint,
            type: getFootprintType(comparisonType) as FootPrintType,
            stageEmission: defaultCompareStageEmission,
            stageType: footprint?.stageType,
            isStagesImpact: Boolean(comparisonFootprint?.stagesImpact),
          });
        }
        setFootprint(baseline);
      })
      .catch((reason) => console.error(reason));
  }, [
    fetchFootprintData,
    baselineName,
    comparisonName,
    baselineType,
    comparisonType,
  ]);

  return {
    footprint,
    footprintStages,
    comparisonFootprintStages,
    setCategoryFilters,
    setAnalysisGroupFilters,
    setStageFilter,
  };
};

export default useFootprint;
